/* Base styles for main-container */
*{
  box-sizing: border-box;
}
.main-container h1 {
  margin: 10px;
   
font-size: 3em;
  font-family: 'Times New Roman', Times, serif;
  text-decoration: underline;
  
}

.mainContainer {
  margin: 10px;
  width: auto;
}

.main-container .container {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main-container .container img {
  height: 90px;
  margin: 10px;
  text-align: cover;
}

/* Media query for tablets and below */
@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1em;
  }

  .mainContainer {
    margin: 5px;
    width: auto;
  }

  .main-container .container {
    padding: 20px 0;
  }

  .main-container .container img {
    height: 90px;
    margin: 5px;
  }
}
@media (min-width: 768px) {
  .main-container h1 {
    font-size: 1em;
  }

  .mainContainer {
    margin: 5px;
    width: auto;
  }

  .main-container .container {
    padding: 20px 0;
  }

  .main-container .container img {
    height: 90px;
    margin: 5px;
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .main-container h1 {
    font-size: 1.75em;
  }

  .mainContainer {
    margin: 5px;
    width: auto;
  }

  .main-container .container {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
  }

  .main-container .container img {
    height: 50px;
    margin: 5px 0;
  }
}
@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.75em;
  }

  .mainContainer {
    margin: 5px;
    width: auto;
  }

  .main-container .container {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
  }

  .main-container .container img {
    height: 50px;
    margin: 5px 0;
  }
}
@media (min-width: 480px) {
  .main-container h1 {
    font-size: 2em;
  }

  .mainContainer {
    margin: 10px;
    width: 50px;
  }

  .main-container .container {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
  }

  .main-container .container img {
    height: 50px;
    margin: 5px 0;
  }
}
@media (max-width: 480px) {
  .main-container h1 {
    font-size: 1.75em;
  }

  .mainContainer {
    margin: 5px;
    width: auto;
  }

  .main-container .container {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
  }

  .main-container .container img {
    height: 50px;
    margin: 5px 0;
  }
}
@media (min-width: 1024px) {
  .main-container h1 {
    font-size: 4em;
  }

  .mainContainer {
    margin: 5px;
    width: auto;
  }

  .main-container .container {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
  }

  .main-container .container img {
    height: 100px;
    margin: 5px 0;
  }
}
