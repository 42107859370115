/* Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffffff;
}

.nav-logo {
  height: 50px;
  width: auto;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
}

.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin-left: 20px;
}

.navbar-menu li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 18px;
}



@media (max-width: 480px) {
 .navbar{
  display: flex;
  gap: 20px;
 }
  .navbar-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
  }

  .navbar-menu.show {
    display: inline;
  }

  .navbar-menu li {
    margin: 10px 0;
  }

  .navbar-toggle {
   display: inline;
  }

  .nav-logo {
    flex-grow: 1;
  }

  .navbar {
    display:block;
  }

}
@media (max-width:768px) {


  .navbar-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
  }
.mynav{
  display: inline;
}
  .navbar-menu.show {
    display: flex;
  }

  .navbar-menu li {
    margin: 10px 0;
  }

  .navbar-toggle {
    display: block;
  }

  .nav-logo {
    flex-grow: 1;
  }

  .navbar {
    display:flex;
  }
}
