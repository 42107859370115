.about {
  margin: 50px;
}
.about h1 {
  font-size: 3.5em;
  font-family: "Times New Roman", Times, serif;
  text-decoration: underline;
}

.front {
  font-size: 4em;
  font-family: "Times New Roman", Times, serif;
  font-weight: 800;
}

.since {
  font-size: 2.5em;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
}

.desc p img {
  height: 500px;
  width: 600px;
  margin: 20px;
  padding: 10px;
  margin-top: -150px;
  box-sizing: border-box;
  text-align: justify;
  box-shadow: 0px 0px 29px 10px rgba(0, 0, 0, 0.75);
}

.desc p {
  position: absolute;
  font-size: 2.5em;
  margin: 0 auto;
  text-align: justify;
  font-family: "Times New Roman", Times, serif;
  display: flex;
  align-items: flex-start;
}

.legacy h1, .products h1, .choose h1 {
  font-size: 4em;
  font-family: 'Times New Roman', Times, serif;
  text-decoration: underline;
  text-transform: uppercase;
}

.legacy p, .products p, .choose p {
  position: absolute;
  font-size: 2.5em;
  margin: 0 auto;
  padding: 20px;
  
  font-family: 'Times New Roman', Times, serif;
  text-align: justify;
}

h1 {
  margin: 0;
  font-size: 2em;
}

.legacy {
  margin-top: 400px;
}

.products {
  margin-top: 300px;
}

.choose {
  margin-top: 450px;
}

.choose h1 {
  font-style: italic;
}

.products p span, .choose p span {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .about h1, .front, .since, .legacy h1, .products h1, .choose h1 {
      font-size: 2.5em; /* Adjust heading sizes for medium screens */
  }
}
@media (min-width: 1024px) {
  .about h1, .front, .since, .legacy h1, .products h1, .choose h1 {
      font-size: 2.5em; /* Adjust heading sizes for medium screens */
  }
}

@media (max-width: 768px) {
  .about h1, .front, .since, .legacy h1, .products h1, .choose h1 {
      font-size:1.75em; /* Smaller heading sizes */
  }
}
@media (min-width: 768px) {
  .about h1, .front, .since, .legacy h1, .products h1, .choose h1 {
      font-size:1.75em; /* Smaller heading sizes */
  }
  .about h1{
    font-size: 4em;
  }
  
  .desc p img {
      height: 200px;
      width: 200px; /* Adjust image size */
  }
  
  .desc p, .legacy p, .products p, .choose p {
      font-size: 1.5em; /* Adjust paragraph sizes */
     
      
  }
}

@media (max-width: 480px) {
  .about h1 {
      font-size: 1.75em;
  }
  
  .front {
      font-size: 1.5em;
  }
  
  .since {
      font-size: 1.5em;
  }

  .desc p img {
      height: 125px;
      width: 125px; /* Smaller image size */
      margin: 10px;
      float: left;
  }
  
  .desc p, .legacy p, .products p, .choose p {
      position: relative; /* Change from absolute for better flow */
      font-size: 0.75em; /* Smaller font size */
      padding: 15px;
      text-align: justify;
     
  }
  
  .legacy {
      margin-top: auto;
  }
  .legacy-head {
      margin-top: 3em;
  }
  
  .products {
    margin-top: 3em;
  }
  
  .choose {
    margin-top: 3em;
  }
}
@media (min-width: 480px) {
  .about h1 {
      font-size: 3em;
  }
  
  .front {
      font-size: 2.5em;
  }
  
  .since {
      font-size: 2em;
  }

  
  
  .desc p, .legacy p, .products p, .choose p {
      position: relative; /* Change from absolute for better flow */
      font-size: 1.5em; /* Smaller font size */
      padding: 15px;
     
  }
  
  .legacy {
      margin-top: auto;
  }
  .legacy-head {
      margin-top: 3em;
  }
  
  .products {
    margin-top: 3em;
  }
  
  .choose {
    margin-top: 3em;
  }
  .legacy h1, .products h1, .choose h1 {
    font-size: 3em;
  }
}
