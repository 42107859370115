

@media (max-width: 768px) {
  .carousel-image {
    height: 45vh;
  }
}

@media (max-width: 480px) {
  .carousel-image {
    height: 45vh;
  }
}
@media (min-width: 480px) {
  .carousel-image {
    height: 45vh;
  }
}
@media (min-width: 1024px) {
  .carousel-image {
    height: 90vh;
   
  }
}





