.footer {
    color: #000000;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 8vw;
    padding-top: 80px;
    width: 100%;
    margin-top: 500px;
}

.footer-content {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 80px;
    
}

.footer-content-left p {
    font-size: 1.25rem;
}

.footer-content-left h3.visit-us {
    font-size: 2.5em;
    font-family: 'Times New Roman', Times, serif;
}

.footer-content-left img {
    width: 200px;
    line-height: 0;
}

.footer-content-center ul, .footer-content-right ul {
    margin-top: -20px;
    margin-left: -30px; 
}

.footer-content-center li, .footer-content-right li {
    margin-bottom: 10px;
    cursor: pointer;
    list-style: none;
    font-size: 1.5rem;
    font-family: 'Times New Roman', Times, serif;
}

.footer-content-right p {
    font-size: 1.25rem;
}

.footer-content li:hover {
    color: #b70000;
}

.footer-content a {
    color: #d9d9d9;
}

.footer-content a:hover {
    color: #fff;
}

.footer-content-left, .footer-content-center, .footer-content-right {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.footer-content-left h2 {
    color: rgb(0, 0, 0);
}

.footer-content-center h2, .footer-content-right h2 {
    color: #fff;
}

.footer-social-icons {
    margin-left: -15px;
}

.footer-social-icons img {
    cursor: pointer;
    width: 40px;
    margin-left: 15px;
}

.app-download-platforms {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: -20px;
}

.app-download-platforms img {
    width: 120px;
    cursor: pointer;
    transition: 0.5s;
}

.app-download-platforms img:hover {
    transform: scale(1.05);
}

.footer hr {
    width: 100%;
    height: 1px;
    background-color: #808080;
    margin-left: -50px;
    border: none;
}
.footer-content-right li .fb {
    width: 50px;
   
 
}
.footer-content-right li ,.fb,.fb{
   text-decoration: none;
   color: #000000;
 
}
.footer-content-right li ,.ig,.ig{
   text-decoration: none;
   color: #000000;
 
}

.footer-content-right li .ig{
    width: 50px;

}
@media (max-width: 1024px) {
    .footer-content {
        grid-template-columns: 1fr; /* Stack columns on medium screens */
        gap: 40px;
    }
}

@media (max-width: 768px) {
    .footer-content-left h3, 
    .footer-content-left p, 
    .footer-content-center li, 
    .footer-content-right li {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
    }
    
    .footer-social-icons img {
        width: 30px; /* Reduce social icon size */
    }
    
    .app-download-platforms img {
        width: 100px; /* Reduce app download image size */
    }
}

@media (max-width: 480px) {
 
  
    .footer {
        margin-top: 3em;
        padding: 10px 5vw; /* Reduce padding for small screens */
        font-size: .75em;
    }
    .footer-content-right li .fb{
     justify-content: flex-start;
     align-items: flex-start;
        width: 32px;
        
        
    }
   
    .footer-content-right li .ig{
        width: 25px;
        

    }
    .footer-content-right ul{
        text-align: justify;
        

    }
    .footer-content-left h3, 
    .footer-content-left p, 
    .footer-content-center li, 
    .footer-content-right li {
        font-size: 0.75rem; /* Adjust font size for smaller screens */
        
    }
    

    .footer-content {
        gap: 20px; /* Reduce gap */
       
    }

    .footer-content-left h3 {
        font-size: 10rem; 
    }
}
@media (min-width: 480px) {
    .footer {
        margin-top: 3em;
        padding: 10px 5vw; /* Reduce padding for small screens */
    }

    .footer-content {
        gap: 20px; /* Reduce gap */
    }

    .footer-content-left h3 {
        font-size: 10rem; 
    }
}
